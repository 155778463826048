// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import { patch } from '@rails/request.js'
import * as bootstrap from "bootstrap"
import { Turbo } from "@hotwired/turbo-rails"

const patchEmail = (url, email) => {
  patch(url, { body: JSON.stringify({ email }) }).then((r) => {
    console.log(r)
  })
}

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


document.addEventListener("turbo:load", () => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

  const emailAdressSpans = document.querySelectorAll(".js-editable-email")

  emailAdressSpans.forEach(element => {
    element.addEventListener("paste", function(e) {
      e.preventDefault();
      var text = (e.originalEvent || e).clipboardData.getData('text/plain');
      document.execCommand("insertHTML", false, text);
  });
    element.addEventListener("blur", (e) => {
      const email = e.target.textContent.trim()
      console.log(email)
      if (email == "" || email.match(validRegex)) {
        patchEmail(e.target.dataset.url, email)
      } else {
        alert("Invalid email provided")
      }
    })
  });
  
  const calculateCombinations = () => {
    const locations = document.querySelectorAll(".js-location-input").length
    const queries = document.querySelectorAll(".js-search-query-input").length
    document.querySelector(".js-combinations").innerHTML = locations * queries
    document.querySelector(".js-combinations-text").classList.remove("d-none")
    return locations * queries
  }

  const addLocationLink = document.querySelector(".js-add-location")

  if (addLocationLink) {
    addLocationLink.addEventListener("click", (event) => {
      event.preventDefault();
      const locationInput = document.querySelector(".js-location-input")
      const newLocationInput = locationInput.cloneNode()
      newLocationInput.placeholder = "Ort / Stadtteil eintragen..."
      newLocationInput.value = ""
      document.querySelector(".js-locations-container").appendChild(newLocationInput)
      const combinations = calculateCombinations()
      console.log(combinations)
      return false
    })
  }

  const addSearchQueryLink = document.querySelector(".js-add-search-query")

  if (addSearchQueryLink) {
    addSearchQueryLink.addEventListener("click", (event) => {
      event.preventDefault();
      const searchQueryInput = document.querySelector(".js-search-query-input")
      const newSearchQueryInput = searchQueryInput.cloneNode()
      newSearchQueryInput.placeholder = "Variation eintragen..."
      newSearchQueryInput.value = ""
      document.querySelector(".js-search-querys-container").appendChild(newSearchQueryInput)
      const combinations = calculateCombinations()
      console.log(combinations)
      return false
    })
  }

  const trigger = document.querySelector(".js-trigger")
  console.log("Checking for trigger")
  if (trigger) {
  const text1 = document.querySelector(".js-text-1")
  const text2 = document.querySelector(".js-text-2")
  const text3 = document.querySelector(".js-text-3")
  const text4 = document.querySelector(".js-text-4")
  const text5 = document.querySelector(".js-text-5")
  setTimeout(function() {
    text1.classList.toggle("d-none")
    text2.classList.toggle("d-none")
  }, 1000)
  setTimeout(function() {
    text2.classList.toggle("d-none")
    text3.classList.toggle("d-none")
  }, 2500)
  setTimeout(function() {
    text3.classList.toggle("d-none")
    text4.classList.toggle("d-none")
  }, 3500)
  setTimeout(function() {
    text4.classList.toggle("d-none")
    text5.classList.toggle("d-none")
  }, 4500)
  const id = trigger.dataset.hash
  console.log("checking first time")
  setTimeout(function() {
  fetch("/en/check/" + id)
  .then((response) => response.json())
  .then((data) => {
    if(data.status == "processed") {
      console.log("yes!!!")
      console.log(trigger.dataset.path)
      Turbo.visit(trigger.dataset.path)
    } else {
      console.log("checking again")
      setTimeout(function() {
        fetch("/en/check/" + id)
      .then((response) => response.json())
      .then((data) => {
        if(data.status == "processed") {
          console.log("yes!!!")
          console.log(trigger.dataset.path)
          Turbo.visit(trigger.dataset.path)
        } else {
          console.log("still nothing")
        }
      });
      }, 3000)
    }
  })}, 1000)
}
})